import './identification.scss';

import { lazy, Suspense, SyntheticEvent, useEffect, useState } from 'react';
import { FaUserCircle } from 'react-icons/fa';

import { useShipmentContext } from '@/resources/components/cart/shipment/Shipment';
import { useWebContext } from '@/resources/hooks/useWebContext';

import { ClientTypes, isFinalCustomer } from '../../../../../services/clients';
import { maskDocument, maskPhone } from '../../../../../services/global';
import { IClient } from '../../../../../store/ducks/order/types';
import userCircleImg from '../../../../assets/images/user-circle-solid.jpg';
import FooterDefaultButton from '../footerDefaultButton/FooterDefaultButton';
import { ShipmentCard } from '../shipmentCard/ShipmentCard';

const IdentifyBy = lazy(() => import('./IdentifyBy'));

interface Props {
  client: IClient;
  orderId: number;
}

function Identification({ client, orderId }: Props) {
  const { step, setStep } = useShipmentContext();
  const [show, setShow] = useState<boolean>(false);
  const { finalCustomer } = isFinalCustomer(client.type);

  const { setShowCPFModal } = useWebContext();

  const handleAddCPF = () => {
    setShowCPFModal(true);
  };

  useEffect(() => {
    if (step === 1 && client.type !== 3) setStep(2);
  }, [client.type, step, setStep]);

  return (
    <>
      <ShipmentCard showModal={show} setShowModal={setShow} warningType="cliente">
        <ShipmentCard.Content
          title="1 - Identificação"
          actions={!finalCustomer}
          footer={
            <>
              {finalCustomer && <FooterDefaultButton title="Identifique-se" showModal={setShow} />}
            </>
          }
        >
          <div className="d-flex align-items-center">
            <div className="avatar">
              {client?.avatar ? (
                <img
                  className="img-fluid"
                  alt={client?.name}
                  src={client?.avatar}
                  onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
                    e.currentTarget.src = userCircleImg;
                  }}
                />
              ) : (
                <FaUserCircle className="icon" />
              )}
            </div>

            <div className="info">
              <h6>{client?.name}</h6>
              {client.type !== ClientTypes.ANONYMOUS && (
                <h6>{maskPhone(client?.identification ?? '', true)}</h6>
              )}

              {client.type !== ClientTypes.ANONYMOUS && !client.cpf ? (
                <h6 className="badge badge-secondary cursor-pointer" onClick={handleAddCPF}>
                  Informar CPF +
                </h6>
              ) : (
                client.cpf && <h6>{maskDocument(client.cpf, true)}</h6>
              )}
            </div>
          </div>
        </ShipmentCard.Content>

        <ShipmentCard.Modal id="shipment-identification" size="lg">
          <Suspense fallback={<div />}>
            <IdentifyBy orderId={orderId} closeModal={() => setShow(false)} />
          </Suspense>
        </ShipmentCard.Modal>
      </ShipmentCard>
    </>
  );
}

export default Identification;
