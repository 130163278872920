import { IDeliveryDiscountData } from '@/store/ducks/deliveryDiscount/types';
import { IOptionGroup, IProductData, Measure } from '@/store/ducks/products/types';

/**
 * Action types
 */
export enum OrderTypes {
  LOAD_REQUEST = '@order/LOAD_REQUEST',
  LOAD_SUCCESS = '@order/LOAD_SUCCESS',
  LOAD_FAILURE = '@order/LOAD_FAILURE',
  SET_VALIDATED = '@order/SET_VALIDATED',
  SET_ZIPCODE = '@order/SET_ZIPCODE',
}

/**
 * Data Types
 */
export interface IOrder {
  id: number;
  status: number;
  shop_id: number;
  company_id: number;
  social_network_id: number;
  company_name: string;
  shop_name_dst: string;
  count_notifications: number;
  created_at: string;
  updated_at: string;
  deliveryman: string | null;
  has_post_sale: boolean;
  integration_name: number;
  integration_code: string | null;
  is_idle: boolean;
  is_late: boolean;
  is_paid: boolean;
  note: string | null;
  payments: IShipmentPayment[] | null;
  payment_name: string | null;
  post_sale_at: string;
  previous_status: number;
  previous_seller: string | null;
  seller_id: number | null;
  seller: {
    id: number | null;
    name: string | null;
  };
  sector: ISector;
  cart: ICart;
  client: IClient;
  shipment: IShipment;
  total: number;
  timezone: number;
  dst_shop_id: number;
  discounts: IOrderDiscount[];
}

interface IOrderDiscount {
  id: number;
  role: 1 | 2 | 3 | 4;
  amount: string;
  author_name: string;
  order_id: number;
  voucher_id: number | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  raw_amount: string;
  raw_type: string;
}

export interface IResumedOrder {
  client: IClientV2;
  count_notifications: number;
  deliveryman_name: string | null;
  dst_shop_id: number;
  id: number;
  identification: string;
  integration_code: string | null;
  integration_type: number;
  is_idle: boolean;
  is_paid: boolean;
  items: IOrderItems;
  last_message: string | null;
  pickup_at: string | null;
  previous_seller_name: string | null;
  previous_status: number;
  sector: ISector;
  seller: {
    id: number | null;
    name: string | null;
  };
  shipment_distance: IDeliveryRate;
  shipment_neighborhood: string | null;
  shipment_shop_name: string;
  shipment_type: string;
  social_network_type: SocialNetworkType;
  status: number;
  status_updated_at: string;
  total: number;
  updated_at: string;
}

export interface IClient {
  id: number;
  type: number;
  name: string;
  cpf: string | null;
  tags: ITag[] | null;
  avatar: string | null;
  born_at: string | null;
  identification: string;
  birthdate: string | null;
  external_code: string | null;
  social_network_type: SocialNetworkType;
  favorite_purchase_shop_id: number;
  use_wholesale_price: boolean;
}

export interface IClientV2 {
  id: number;
  name: string;
  photo: string | null;
}

export type SocialNetworkType = 'phone' | 'whatsapp' | 'facebook-api' | 'instagram-api';

interface ITag {
  id: string;
  color: string;
  description: string;
}

interface ISector {
  id: number;
  name: string;
}

export interface IShipmentPayment {
  id: number;
  name: string;
  order_id: number;
  total_paid: number;
  total_negotiated: number;
  payment_url: string;
  payment_qrcode: string;
  payment_method: MethodType;
  payment_plan_name: string;
  payment_plan_id: number | null;
  type: 'custom-payment' | 'picpay';
  status: 'paid' | 'not-paid';
  transaction_env: 'delivery' | 'system';
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  integration_id: number;
  external_code: string;
  data: any /* returns from integration */;
  created_by: string;
  integration: any;
}

export type IShipmentType = 'delivery' | 'shop';

export interface IShipment {
  type: IShipmentType | null;
  pickup_at: string | null;
  address: IAddress | IShopAddress;
  delivery: IDelivery | null;
  delivery_rate: IDeliveryRate | null;
  attendance_types: IAttendanceType | null;
  carrier: string | null;
  refresh_carrier: boolean | null;
  full_name_carrier: string | null;
  delivery_discount: IDeliveryDiscountData | null;
}

export interface IAttendanceType {
  'google-maps': IIntegrationAttendance;
  'in-store': IIntegrationAttendance;
  'frenet-api': IIntegrationAttendance;
}

export interface IIntegrationAttendance {
  description: string;
  order_min_value: number;
  name?: string;
}

export interface IAddress {
  id: number;
  city: string;
  state: string;
  number: string;
  street: string;
  zipcode: string;
  country: string;
  complement: string;
  neighborhood: string;
  reference: string;
}

export interface IShopAddress {
  id: number;
  name: string;
}

interface IDelivery {
  id: number;
  type: string;
}

export interface IDeliveryRate {
  box: {
    id: number;
    width: string;
    height: string;
    length: string;
    weight: string;
    is_default: boolean;
    shop_id: number;
  } | null;
  price: number | null;
  distance: number | null;
}

export interface ICart {
  discount: number;
  total: number;
  products: ICartProduct[];
  delivery_tax: number;
  items_total: number;
  payment_tax: number;
}

export interface ICartProduct {
  id: number;
  product_id: number;
  ean: string;
  title: string;
  price: number;
  subtotal: number;
  price_type: PriceType;
  default_price: number;
  current_amount: number;
  image: string;
  images: string[];
  quantity: number;
  separated: boolean;
  is_available: boolean;
  measure: Measure;
  current_measure: Measure;
  options: IAnswer[];
  description: string;
  external_code: string;
  data: IProductData | null;
  options_group: IOptionGroup | null;
  see_as_unit: boolean;
  average_weight: number;
  delivery_discount_data: IDeliveryDiscountData;
  medicine_strip: 1 | 2 | null;
  prescription_retention: boolean;
}

export type PriceType = 'default_price' | 'promo_price' | 'current_price' | 'wholesales_price';

export interface ICartItemValues {
  ean: string;
  product_id: number | undefined;
  quantity: string;
  options: IQuestion[];
  see_as_unit: boolean;
  average_weight: number;
}

export interface IQuestion {
  select_options: IAnswer[];
  add_order_quantity: boolean;
  title: string | null;
  id: number;
  sequence: number;
  minimum_of_answers: number;
  maximum_of_answers: number;
}

export interface IAnswer {
  id: number;
  title: string;
  value: number;
  quantity: any;
  chosen: boolean;
  questionId: number;
}

export interface ICartItemRequest {
  ean: string;
  quantity: number;
  product_id: number;
  options: IAnswer[];
}

export interface IPaymentMethod {
  id: number;
  shipment: IShipmentType;
  observations: string | null;
  payment_methods: MethodType;
  can_finish_unpaid: boolean;
  payment_plans: IPaymentPlan[];
  payment_name: string;
  deleted_At: string | null;
}

export type MethodType =
  | 'credit'
  | 'debit'
  | 'pix'
  | 'bill'
  | 'bank-transfer'
  | 'cash'
  | 'picpay'
  | 'multi-pay'
  | 'convention'
  | 'meal-voucher'
  | 'food-voucher';

export interface IPaymentPlan {
  id: number;
  total: number;
  addition_value: number;
  addition_percent: number;
  installments_value: number;
  installments_number: number;
  name: string;
  min_value: number;
}

export interface IShipmentPaymentPlan {
  id: number;
  name: string;
  shop_id: number;
  min_value: number;
  external_code: string | null;
  addition_value: number;
  addition_percent: number;
}

export interface IUniquePayment {
  method: IPaymentMethod;
  plan: IShipmentPaymentPlan;
}

interface IOrderItems {
  ean: string;
  title: string;
  amount: number;
  options: IAnswer[];
  discount: number;
  quantity: number;
  separated: boolean;
  product_id: number;
  price_value: number;
  see_as_unit: boolean;
  smarttag: string | null;
  measure: Measure;
  is_available: boolean;
  price_type: PriceType;
  current_amount: number;
  value_promotion: number;
  service_schedules: IServiceSchedule[];
  current_measure: Measure;
}

interface IServiceSchedule {
  id: number;
  name: string;
  question: string;
  image: string | null;
  observations: string | null;
  show_entire_period: boolean;
  service_schedule_groups: IGroupService[];
  info: string | null;
}

interface IGroupService {
  id: number;
  name: string;
  company_id: number;
  created_at: string;
  updated_at: string;
}

/**
 * State type
 */
export interface IOrderState {
  readonly data: IOrder | null;
  readonly loading: boolean;
  readonly error: boolean;
  readonly validated: boolean;
  readonly zipcodeAnswered: boolean;
}
