import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import { maskPhone } from '@/services/global';

import { SocialNetworkType } from '../../../../store/ducks/order/types';

interface Props {
  show: boolean;
  socialNetworkType: SocialNetworkType;
  clientIdentification: string;
  handleClose: () => void;
}

function SendOrderAlert({ show, socialNetworkType, clientIdentification, handleClose }: Props) {
  const [showModal, setShowModal] = useState<boolean>(show);
  const getSocialTranslated = () => {
    switch (socialNetworkType) {
      case 'whatsapp':
        return 'WhatsApp';
      case 'instagram-api':
        return 'Instagram';
      case 'facebook-api':
        return 'Facebook';
      default:
        return '';
    }
  };

  useEffect(() => setShowModal(show), [show]);

  return (
    <Modal
      id="finish-order-modal"
      show={showModal}
      onHide={handleClose}
      centered={true}
      animation={false}
    >
      <Modal.Body className="text-center p-2">
        <svg
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="my-3"
        >
          <path
            d="M120 60C120 93.1371 93.1371 120 60 120C26.8629 120 0 93.1371 0 60C0 26.8629 26.8629 0 60 0C93.1371 0 120 26.8629 120 60Z"
            fill="#6BFF8F"
            fillOpacity="0.3"
          />
          <path
            d="M104.854 60C104.854 84.7724 84.7724 104.854 60 104.854C35.2276 104.854 15.1456 84.7724 15.1456 60C15.1456 35.2276 35.2276 15.1456 60 15.1456C84.7724 15.1456 104.854 35.2276 104.854 60Z"
            fill="#2CCD68"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M110.68 60C110.68 87.9896 87.9896 110.68 60 110.68C32.0104 110.68 9.32039 87.9896 9.32039 60C9.32039 32.0104 32.0104 9.32039 60 9.32039C87.9896 9.32039 110.68 32.0104 110.68 60ZM60 104.854C84.7724 104.854 104.854 84.7724 104.854 60C104.854 35.2276 84.7724 15.1456 60 15.1456C35.2276 15.1456 15.1456 35.2276 15.1456 60C15.1456 84.7724 35.2276 104.854 60 104.854Z"
            fill="#71E08C"
            fillOpacity="0.5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M85.1399 43.3067C86.9598 45.1266 86.9598 48.0773 85.1399 49.8972L58.9263 76.1108C58.0458 76.9913 56.8495 77.4828 55.6043 77.4757C54.3591 77.4685 53.1685 76.9633 52.2982 76.0727L39.7739 63.2572C37.975 61.4164 38.0089 58.466 39.8496 56.6671C41.6904 54.8682 44.6408 54.9021 46.4397 56.7428L55.6692 66.1869L78.5494 43.3067C80.3693 41.4868 83.32 41.4868 85.1399 43.3067Z"
            fill="white"
          />
        </svg>

        <h6 className="mb-0" style={{ fontSize: '1.5rem' }}>
          Pedido enviado para a loja!
        </h6>
        <p style={{ fontSize: '0.875rem' }}>
          Não se preocupe, enviaremos atualizações do seu pedido pelo seu{' '}
          <b>
            {getSocialTranslated()} {maskPhone(clientIdentification)}
          </b>
          .
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="primary"
          onClick={handleClose}
          className="w-100 font-weight-bold mx-3"
          style={{ borderRadius: '7px', height: '50px' }}
        >
          Ok! Entendi
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SendOrderAlert;
