import { Modal } from 'react-bootstrap';
import { FaTags } from 'react-icons/fa';
import { useSelector } from 'react-redux';

import CustomModal from '@/resources/components/global/CustomModal';
import Skeleton from '@/resources/components/global/Skeleton';
import { ApplicationState } from '@/store';
import { IDeliveryDiscountData } from '@/store/ducks/deliveryDiscount/types';

import DeliveryDiscountItem from './DeliveryDiscountItem';

interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
  showDiscounts?: boolean;
}

function ShippingDiscountModal({ show, setShow, showDiscounts = true }: Props) {
  const shippingDiscount = useSelector<ApplicationState, IDeliveryDiscountData[]>(
    (state) => state.deliveryDiscount.data,
  );

  const isLoading = useSelector<ApplicationState, boolean>(
    (state) => state.deliveryDiscount.loading,
  );

  const getShippingDiscounts = () => {
    if (showDiscounts) {
      return shippingDiscount.filter((discount) => !!discount.type_discount);
    }

    return shippingDiscount?.filter((discount) => discount.type_discount === 'free');
  };

  const shippingRules = getShippingDiscounts();

  return (
    <CustomModal
      id="shipping-discount-modal"
      show={show}
      onHide={() => setShow(false)}
      centered={true}
      size="lg"
      className="shipping-discount-modal modal-rounded"
    >
      <Modal.Header closeButton className="h5 mb-0">
        <span className="d-flex flex-row align-items-center">
          <FaTags className="mr-2" style={{ color: '#0088FF' }} />
          Descontos disponíveis
        </span>
      </Modal.Header>

      <Modal.Body className="d-flex flex-column gap-2">
        {!shippingRules.length && !isLoading && (
          <div className="text-center">Nenhum desconto disponível.</div>
        )}

        {!shippingRules.length && isLoading && (
          <>
            {Array.from({ length: 3 }, (_, i) => (
              <Skeleton key={i} style={{ width: '100%', height: '100px' }} />
            ))}
          </>
        )}

        {shippingRules && (
          <>
            {shippingRules.map((discount) => (
              <DeliveryDiscountItem key={discount.id} discount={discount} />
            ))}
          </>
        )}
      </Modal.Body>
    </CustomModal>
  );
}

export default ShippingDiscountModal;
