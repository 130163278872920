import './askCpfModal.scss';

import React, { ChangeEvent, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { AiOutlineIdcard } from 'react-icons/ai';
import { IoMdAlert } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { useTextColor } from '@/resources/hooks/useTextColor';
import { useWebContext } from '@/resources/hooks/useWebContext';
import { addClientCPF } from '@/routes/clients';
import { maskDocument, unmaskNumber, validateCpfOfCnpj } from '@/services/global';
import { setCpfAnswered } from '@/store/ducks/client/action';

import CustomModal from '../global/CustomModal';

interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
  setAddress?: (show: boolean) => void;
}

function AskCpfModal({ show, setShow, setAddress }: Props) {
  const dispatch = useDispatch();

  const { configs, order } = useWebContext();
  const textColor = useTextColor(configs.default_color);
  const text = configs.ask_cpf_question ?? 'Deseja informar seu CPF/CNPJ?';

  const [clientCpf, setClientCpf] = useState<string>('');
  const [isCpfValid, setIsCpfValid] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const setCpfMask = (cpf: string) => {
    const maskedCpf = maskDocument(cpf);

    setClientCpf(maskedCpf);
  };

  const storeClientCpf = (cpf: string) => {
    const cpfFormatted = unmaskNumber(cpf);

    if (order?.client.id) {
      addClientCPF(order?.client.id, cpfFormatted)
        .then(() => {
          setIsLoading(false);
          setShow(false);

          dispatch(setCpfAnswered(true));
          toast('CPF cadastrado com sucesso!', { type: 'success' });
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);

          const message = error?.response?.data?.message ?? 'Não foi possível cadastrar seu CPF.';

          toast(message, { type: 'error' });
        })
        .finally(() => {
          if (setAddress) setAddress(true);
        });
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isCpfValid || clientCpf.length === 0) {
      toast('Insira um CPF válido!', { type: 'warning' });
      return;
    }

    const cpf = event.currentTarget.cpf.value;

    setIsLoading(true);
    storeClientCpf(cpf);
  };

  return (
    <CustomModal
      id="ask-cpf-modal"
      show={show}
      onHide={() => {
        setShow(false);

        if (configs.ask_cpf && !configs.cpf_required && setAddress) {
          setAddress(true);
        }
      }}
      centered={false}
    >
      <Modal.Header className="border-0" closeButton></Modal.Header>

      <Modal.Body>
        <div className="ask-cpf-title w-100">
          {configs.logo ? (
            <img
              width={185}
              height={55}
              src={configs.logo}
              alt={configs.company_name}
              className="img-fluid"
            />
          ) : (
            <span style={{ color: textColor }}>{configs.company_name}</span>
          )}
        </div>

        <div className="ask-cpf-body">
          <div className="ask-cpf-question">
            <span>{text}</span>
          </div>

          {configs.ask_cpf_description && (
            <div className="ask-cpf-description">
              <span>{configs.ask_cpf_description}</span>
            </div>
          )}

          <form onSubmit={handleSubmit}>
            <div className="ask-cpf-input">
              <AiOutlineIdcard className="input-icon" size={20} />
              <input
                id="cpf-input"
                name="cpf"
                type="text"
                value={clientCpf}
                className={`cpf-field w-100 ${!isCpfValid && clientCpf.length > 0 && 'is-invalid'}`}
                title="Insira seu CPF"
                placeholder="Digite seu CPF"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setCpfMask(e.target.value);
                }}
                onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
                  const value = e.clipboardData?.getData('text/plain');

                  setCpfMask(value);
                }}
                onBlur={() => setIsCpfValid(validateCpfOfCnpj(clientCpf))}
              />
            </div>

            {!isCpfValid && clientCpf.length > 0 && (
              <span className="text-danger d-flex align-items-center">
                <IoMdAlert /> <small className="px-1">Insira um CPF ou CNPJ válido!</small>
              </span>
            )}

            <div className="ask-cpf-footer">
              <Button variant="ligth" onClick={() => setShow(false)} size="sm">
                Cancelar
              </Button>

              <Button
                type="submit"
                style={{
                  backgroundColor: configs.default_color,
                  borderColor: configs.default_color,
                  color: textColor,
                }}
                size="sm"
                disabled={isLoading}
              >
                Confirmar
              </Button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </CustomModal>
  );
}

export default AskCpfModal;
