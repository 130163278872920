import { toast } from 'react-toastify';

import { createErrorMessage } from '@/services/handlers';
import { IDeliveryIntegration } from '@/store/ducks/deliveryMethods/types';

import api from '../services/api';
import { store } from '../store';
import * as OrderActions from '../store/ducks/order/actions';
import { ICartItemRequest, IOrder, IPaymentMethod } from '../store/ducks/order/types';

const { dispatch } = store;

export const validateOrder = async (shopId: number, orderId: number | null) => {
  const { REACT_APP_SHOP_DEFAULT = null } = process.env;
  const isCartPage = window.location.href.includes('/cart');

  dispatch(OrderActions.loadRequest());

  return api
    .get<{ shop_id: number; order_id: number; default_shop_id?: number }, IOrder>(
      '/validate-order',
      {
        params: {
          shop_id: shopId,
          order_id: orderId,
          default_shop_id: !isCartPage ? REACT_APP_SHOP_DEFAULT : null,
        },
      },
    )
    .then((order) => {
      dispatch(OrderActions.loadSuccess(order));

      return order;
    })
    .catch((err) => {
      console.error(err);
      dispatch(OrderActions.loadFailure());

      throw err;
    });
};

export const getOrder = async (orderId: number) => {
  OrderActions.loadRequest();

  return api
    .get<void, IOrder>(`/orders/${orderId}`)
    .then((order) => dispatch(OrderActions.loadSuccess(order)))
    .catch((err) => {
      OrderActions.loadFailure();
      console.error(err);
    });
};

export const addCartItem = async (
  orderId: number,
  cartItem: ICartItemRequest,
): Promise<IOrder | null> => {
  dispatch(OrderActions.loadRequest());

  return api
    .put<ICartItemRequest, IOrder>(`/orders/${orderId}/cart/add`, { ...cartItem })
    .then((order) => {
      dispatch(OrderActions.loadSuccess(order));

      return order;
    })
    .catch((e) => {
      dispatch(OrderActions.loadFailure());

      const message = createErrorMessage(e, 'Não foi possível adicionar ao carrinho');

      throw new Error(message);
    });
};

export const changeItem = async (
  orderId: number,
  cartItem: ICartItemRequest,
): Promise<IOrder | null> => {
  dispatch(OrderActions.loadRequest());

  return api
    .put<ICartItemRequest, IOrder>(`/orders/${orderId}/cart/receive`, { ...cartItem })
    .then((order) => {
      dispatch(OrderActions.loadSuccess(order));

      return order;
    })
    .catch((err) => {
      console.error(err);
      dispatch(OrderActions.loadFailure());

      const message = createErrorMessage(err, 'Não foi possível alterar o item');
      const type = err.response.data.type || 'error';

      throw new Error(message, { cause: type });
    });
};

export const removeCart = async (orderId: number) => {
  dispatch(OrderActions.loadRequest());

  return api
    .put<void, { order: IOrder }>(`/orders/${orderId}/clear`)
    .then(({ order }) => {
      dispatch(OrderActions.loadSuccess(order));
    })
    .catch((err) => {
      console.error(err);
      dispatch(OrderActions.loadFailure());

      const message = createErrorMessage(err, 'Não foi possível limpar o carrinho');
      const type = err.response.data.type || 'error';

      throw new Error(message, { cause: type });
    });
};

export const switchClient = async (
  orderId: number,
  clientId: number,
  socialNetworkId: number,
  message: string,
) => {
  return api
    .put<{ client_id: number; social_network_id: number }, IOrder>(
      `/orders/${orderId}/switch-client-id`,
      {
        client_id: clientId,
        social_network_id: socialNetworkId,
      },
    )
    .then((order) => {
      toast(message, { type: 'success' });
      dispatch(OrderActions.loadSuccess(order));

      return order;
    })
    .catch((err) => {
      const { type, message } = err.response.data;

      toast(message, { type });
      dispatch(OrderActions.loadFailure());

      return null;
    });
};

export const setStatus = async (orderId: number, status: number) => {
  return api
    .put<{ status: number }, IOrder>(`/orders/${orderId}/set-status`, { status })
    .then((order) => dispatch(OrderActions.loadSuccess(order)));
};

export const sendCartResume = async (orderId: number, title: string) =>
  api.post<{ title: string }, string>(`/orders/${orderId}/cart-resume`, { title });

export const setShipmentDelivery = async (orderId: number, integration: IDeliveryIntegration) => {
  return api.put<
    { integration_id: number; price: number; delivery_date: string; carrier: string },
    IOrder
  >(`/orders/${orderId}/shipment/delivery`, {
    integration_id: integration.integration_id,
    price: integration.price,
    delivery_date: integration.delivery_date,
    carrier: integration.name,
  });
};

export const setShipmentAddress = async (
  orderId: number,
  addressId: number,
  integration: { type: 'shop' | 'delivery' | 'in-store' },
) => {
  dispatch(OrderActions.loadRequest());

  return api
    .put<{ id: number; type: 'delivery' | 'shop' }, IOrder>(`/orders/${orderId}/shipment/address`, {
      id: addressId,
      type: integration.type === 'delivery' ? 'delivery' : 'shop',
    })
    .then(async (order) => {
      dispatch(OrderActions.loadSuccess(order));

      return order;
    })
    .catch((err) => {
      console.error(err);

      const { type, message } = err.response.data;

      toast(message, { type: type ?? 'error' });
      dispatch(OrderActions.loadFailure());

      throw err;
    });
};

export const setShipmentPickupAt = async (orderId: number, pickupAt: string) => {
  dispatch(OrderActions.loadRequest());

  return api
    .put<{ pickup_at: string }, IOrder>(`/orders/${orderId}/shipment/pickup_at`, {
      pickup_at: pickupAt,
    })
    .then((order) => dispatch(OrderActions.loadSuccess(order)))
    .catch((err) => {
      dispatch(OrderActions.loadFailure());
      console.error(err);
    });
};

export const setShipmentPayment = async (orderId: number, paymentId: number, planId: number) => {
  dispatch(OrderActions.loadRequest());

  return api
    .put<{ id: number }, IOrder>(`/orders/${orderId}/payment`, {
      integration_id: paymentId,
      payment_plan_id: planId,
    })
    .then((order) => {
      dispatch(OrderActions.loadSuccess(order));

      return order;
    })
    .catch((err) => {
      console.error(err);
      dispatch(OrderActions.loadFailure());

      throw err;
    });
};

export const setShipmentPaymentPlan = async (orderId: number, paymentPlanId: number) => {
  dispatch(OrderActions.loadRequest());

  return api
    .put<{ payment_plan_id: number }, IOrder>(`/orders/${orderId}/payment-plan`, {
      payment_plan_id: paymentPlanId,
    })
    .then((order) => dispatch(OrderActions.loadSuccess(order)))
    .catch((err) => {
      console.error(err);
      dispatch(OrderActions.loadFailure());
    });
};

export const setNote = async (orderId: number, note: string) => {
  dispatch(OrderActions.loadRequest());

  return api
    .post<{ note: string }, IOrder>(`/orders/${orderId}/set-note`, { note })
    .then((order) => dispatch(OrderActions.loadSuccess(order)))
    .catch((err) => {
      console.error(err);
      dispatch(OrderActions.loadFailure());
    });
};

export const getPaymentMethods = async (orderId: number) => {
  return api.get<void, IPaymentMethod[]>(`/orders/${orderId}/payment-plans`);
};

export const setShopByZipcode = async (
  zipcode: string,
  companyId: number,
  orderId: number,
): Promise<IOrder | null> => {
  dispatch(OrderActions.loadRequest());

  return api
    .put<{ zipcode: string }, IOrder>(`/delivery-area/set-shop/${orderId}`, {
      zipcode,
      company_id: companyId,
    })
    .then((order) => {
      dispatch(OrderActions.loadSuccess(order));

      return order;
    })
    .catch((err) => {
      console.error(err);
      dispatch(OrderActions.loadFailure());

      return null;
    });
};

export const deletePayment = async (paymentId: number) => {
  return api.delete(`/payments/${paymentId}`).catch((err) => {
    console.error(err);
  });
};
